import React, { useEffect, useState } from 'react';
import { defer } from 'rxjs';
import { SIZE, EMPTY_STRING, WARNING } from '../../constant/text';
import { withTranslation } from './translation';
import { Label, FieldErrorText } from './text';

const TextField = props => {
    const { dataAttribute, t, min, name, required = false, errorType = WARNING, errorMessage = EMPTY_STRING, label = EMPTY_STRING, onChange = () => { }, onblur = () => { },
        id, value, placeholder = EMPTY_STRING, size = SIZE.M, className = EMPTY_STRING, outerClassName = EMPTY_STRING, type = 'text', onKeyPress = () => { },
        disabled = false, maxLength = undefined } = props;
    const [error, setError] = useState(errorMessage);

    useEffect(() => {
        const subscription = defer(() => setError(errorMessage)).subscribe();

        return subscription.unsubscribe();
    }, [errorMessage]);

    const onTextFieldChange = e => {
        onChange(e.target.value, e);
    };

    return (
        <div className={`labelAndField ${outerClassName}`}>
            <Label value={`${t(label)}`} required={required} />
            <input
                data-test={dataAttribute}
                name={name}
                type={type}
                id={id}
                className={`textField ${size} ${className} field`}
                value={value ? value : EMPTY_STRING}
                placeholder={t(placeholder)}
                onChange={e => onTextFieldChange(e)}
                disabled={disabled}
                autoComplete="off"
                onKeyPress={onKeyPress}
                onBlur={() => onblur()}
                min={min}
                maxLength={maxLength}
            />
            <FieldErrorText value={error} type={errorType} />
        </div>

    );
};

export default withTranslation()(TextField);
