import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import moment from 'moment-timezone';
import { parseJwt, getFromLocal } from '../api/localStorageUtil';
// eslint-disable-next-line import/no-cycle
import { getRoleId } from '../api/util';
// eslint-disable-next-line import/no-cycle
import {
    ROLES,
    DEFAULT_USER_ROLE_ID,
    PREDICAREUSER_PREDICAREREGION_ROLES,
    PREDICAREUSER_OTHERREGION_ROLES,
    PREDICAREUSER_OTHERORGANIZATION_ROLES,
    PREDICAREUSER_OTHERDEPARTMENT_ROLES,
    LOCALADMINUSER_ORGANIZATION_ROLES,
    LOCALADMINUSER_DEPARTMENT_ROLES,
    DEPARTMENT_USER_ROLE_ID,
    ADMIN_PAGES_PREFIX,
    ROOT_PAGE,
    MANAGE_VERSIONS_PAGE,
    SEARCH_PAGE
} from '../constant/route';
import {
    EXTRA_DOTS, EMPTY_STRING, ADMIN, ESS_CLASSIFY_RANGE_CAPACITY, ESS_SCROLL_REF_PREFIX, ESS_NEXT_NUMBER_STEP, LOCAL_LANGUAGE_CODE,
    LOCAL_LANGUAGES, LOCAL_SELECTED_PRODUCT, LOCAL_USER_TYPE, PREDICARE, DROPDOWN_SELECT_TEXT_VALUE,
    QUERY_SPLITTER, QUERY_PARAMS_SPLITTER, PARAM_SPLITTER, EMAIL_REGEXP, LOCAL_ACCOUNT_USERS
} from '../constant/text';

export const convertObjKeysToDropdownList = obj => {
    const dropdownList = [];

    Object.keys(obj).forEach(key => {
        dropdownList.push({ label: key, value: key });
    });

    return dropdownList;
};

export const convertObjToDropdownList = obj => {
    const dropdownList = [];

    Object.keys(obj).forEach(key => {
        dropdownList.push({ label: obj[key], value: key });
    });

    return dropdownList;
};

export const convertObjectsToDropdownList = (objList, key, value) => {
    const dropdownList = [];

    objList && !isEmpty(objList) && objList.forEach(obj => {
        if (obj[key] && obj[value]) {
            dropdownList.push({ label: obj[value], value: obj[key] });
        } else {
            dropdownList.push(obj);
        }
    });

    return dropdownList;
};

export const convertStringsToDropdownList = strList => {
    const dropdownList = [];

    strList && !isEmpty(strList) && strList.forEach(str => {
        dropdownList.push({ label: str, value: str });
    });

    return dropdownList;
};

export const removeDuplicatesFromArray = arr => arr.filter((item, index) => {
        const itemStringified = JSON.stringify(item);

        return index === arr.findIndex(selfItem => JSON.stringify(selfItem) === itemStringified);
    });

export const getExistsOrNewFirst = (arr, id) => {
    const firstFoundElement = arr.find(item => {
        if (item instanceof Object) {
            return item.id === id;
        }

        return item === id;
    });

    let newId;
    if (arr[0] instanceof Object) {
        newId = arr[0].id;
    } else {
        newId = arr[0];
    }

    return firstFoundElement && id ? id : newId;
};

export const isDepartmentUserExists = users => users.filter(user => user.role.id === DEPARTMENT_USER_ROLE_ID).length > 0;

export const getRolesDropdownListFromRoles = (roles, users) => {
    if (isDepartmentUserExists(users)) {
        roles = roles.filter(roleId => roleId !== DEPARTMENT_USER_ROLE_ID);
    }

    return roles.map(roleId => ({ label: ROLES[roleId].translationCode, value: roleId }));
};

export const getRolesDropdownListForNode = (node, users) => {
    const { accountType: { id: accountTypeId } } = node;
    let roles = [];
    if (accountTypeId === 0) {
        roles = getRolesDropdownListFromRoles(PREDICAREUSER_PREDICAREREGION_ROLES, users);
    } else if (accountTypeId === 1) {
        roles = getRolesDropdownListFromRoles(PREDICAREUSER_OTHERREGION_ROLES, users);
    } else if (accountTypeId === 2) {
        if (isPredicare()) {
            roles = getRolesDropdownListFromRoles(PREDICAREUSER_OTHERORGANIZATION_ROLES, users);
        } else {
            roles = getRolesDropdownListFromRoles(LOCALADMINUSER_ORGANIZATION_ROLES, users);
        }
    } else if (isPredicare()) {
        roles = getRolesDropdownListFromRoles(PREDICAREUSER_OTHERDEPARTMENT_ROLES, users);
    } else {
        roles = getRolesDropdownListFromRoles(LOCALADMINUSER_DEPARTMENT_ROLES, users);
    }

    return roles;
};

export const getOverviewRolesList = () => {
    const roleId = getRoleId();
    const list = [];
    forEach(ROLES, (val, key) => {
        if (key >= roleId) {
            list.push({ value: key, label: val.translationCode });
        }
    });

    return list;
};

export const getLocalDefualtPublishedPackage = list => {
    const id = find(list, item => item.localDefault) ? find(list, item => item.localDefault).id : null;

    return id;
};

export const getOverviewPublishedPackageList = list => {
    const packages = map(list, item => ({
        label: `${item.type} ${item.version} - ${item.languageName}`,
        value: item.id
    }));

    return packages;
};

export const getAllLanguages = () => {
    const languages = getFromLocal(LOCAL_LANGUAGES, true);
    if (!languages || isEmpty(languages)) {
        return ([]);
    }

    return languages;
};

export const getAssignedProduct = () => {
    const product = getFromLocal(LOCAL_SELECTED_PRODUCT, true);
    if (!product || isEmpty(product)) {
        return ({});
    }

    return product;
};

export const convertLanguagesToDropdownFormat = key => {
    const languages = getAllLanguages();
    let langs = [];
    langs = convertObjectsToDropdownList(languages, key, 'languageName');

    return langs;
};

export const getLanguageNameByCode = langCode => {
    let langName = EMPTY_STRING;
    const languages = getAllLanguages();
    const langs = languages.filter(lang => lang.languageCode === langCode);
    if (!isEmpty(langs)) {
        langName = langs[0].languageName;
    }

    return langName;
};

export const getLanguageNameById = langId => {
    let langName = EMPTY_STRING;
    const languages = getAllLanguages();
    const langs = languages.filter(lang => Number(lang.languageId) === Number(langId));
    if (!isEmpty(langs)) {
        langName = langs[0].languageName;
    }

    return langName;
};

export const getLanguageIdByCode = (langCode = EMPTY_STRING) => {
    const languages = getAllLanguages();
    let languageCode;
    if (isEmpty(langCode)) {
        languageCode = getFromLocal(LOCAL_LANGUAGE_CODE);
    } else {
        languageCode = langCode;
    }

    const filteredLangs = languages.filter(item => item.languageCode === languageCode);
    if (isEmpty(filteredLangs)) {
        return 1;
    }

    return filteredLangs[0].languageId;
};

export const getUserLanguageId = () => {
    const i18nextLanguageCode = getFromLocal(LOCAL_LANGUAGE_CODE, true);

    return getLanguageIdByCode(i18nextLanguageCode);
};

export const getLanguageCodeById = langId => {
    const languages = getAllLanguages();
    const filteredLang = languages.filter(item => Number(item.languageId) === Number(langId));
    if (!isEmpty(filteredLang)) {
        return filteredLang[0].languageCode;
    }

    return getFromLocal(LOCAL_LANGUAGE_CODE);
};

export const splitRange = (start, end, capacity = ESS_CLASSIFY_RANGE_CAPACITY) => {
    const splittedRange = [];
    let min = start;
    for (let max = start; max <= end; max += 1) {
        max = min - 1 + capacity;
        if (max > end) {
            max = end;
        }
        splittedRange.push({ min, max });
        min = max + 1;
    }

    return splittedRange;
};

export const getMenuList = () => {
    const roleId = getRoleId();
    const role = ROLES[roleId] || ROLES[DEFAULT_USER_ROLE_ID];

    if (role.adminView && isAdminRoute()) {
        return role.menu;
    }
    return ROLES[DEFAULT_USER_ROLE_ID].menu;
};

export const getUserStartPage = () => SEARCH_PAGE;

export const getAdminStartPage = () => MANAGE_VERSIONS_PAGE;

export const getMatchedWindowPath = (url, menuList) => {
    let path = EMPTY_STRING;
    menuList.forEach(menu => {
        menu.paths.forEach(pathName => {
            const allPatterns = new RegExp(`(${pathName})(\\/)?`);
            const exceptPatterns = new RegExp(`(${pathName})(\\/\\w)`);
            if (url.match(allPatterns) && !url.match(exceptPatterns)) {
                path = pathName;
            }
        });
    });

    return path;
};

export const allowedRoutesForRole = url => !isEmpty(getMatchedWindowPath(url, getMenuList()));

export const getSideBarList = menuList => {
    const accountCount = getAccountUsers().length;
    if (accountCount <= 1) {
        delete menuList.CHANGE_ACCOUNT;
    }

    return convertObjKeysToDropdownList(menuList);
};

export const findPos = obj => {
    let curtop = 0;
    if (obj.offsetParent) {
        do {
            curtop += obj.offsetTop;
        } while (obj === obj.offsetParent);
    }

    return curtop;
};

export const scrollToEssRange = (id, nextAvailableStep = ESS_NEXT_NUMBER_STEP) => {
    const el = document.getElementById(id);
    if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        document.body.scrollTop = 0;
    } else {
        const newId = `${ESS_SCROLL_REF_PREFIX}${Number(id.split(ESS_SCROLL_REF_PREFIX)[1]) + nextAvailableStep}`;
        scrollToEssRange(newId, nextAvailableStep);
    }
};

export const getAccountUsers = () => {
    const accountUsers = getFromLocal(LOCAL_ACCOUNT_USERS, true);
    if (!accountUsers) {
        return [];
    }

    return accountUsers;
};

export const isValidLicensedUser = userId => {
    const accountUsers = getAccountUsers();
    let validUsers = [];
    validUsers = accountUsers.find(accountUser => {
        const { id, validLicense } = accountUser;

        return Number(id) === Number(userId) && validLicense;
    });

    return !isEmpty(validUsers);
};

export const isAdminRoute = () => window.location.pathname.startsWith(ADMIN_PAGES_PREFIX);

export const getAccountId = () => {
    const { userDetail } = parseJwt();
    const { accountId } = userDetail;

    return accountId;
};

export const isPredicareRegion = node => node && node.name === PREDICARE;

export const isPredicare = () => getRoleId() === 10;

export const isLocalAdmin = () => getRoleId() === 30;

export const isCentralAdmin = () => getRoleId() === 20;

export const maxWordLength = (word, length) => {
    if (word && !isEmpty(word) && word.length > length) {
        word = word.substring(0, length);
        word += EXTRA_DOTS;
    }

    return word;
};

export const persistOpenNodes = (nodes, childAccs) => {
    Object.keys(childAccs).forEach(childAccKey => {
        Object.keys(nodes).forEach(nodeKey => {
            if (childAccKey === nodeKey) {
                if (nodes[nodeKey].isOpen) {
                    childAccs[childAccKey].isOpen = nodes[nodeKey].isOpen;
                }
            }
        });
    });

    return childAccs;
};

export const getFilteredSearchUsers = (searchUsers, searchWord, roleId) => {
    let filteredSearchUsers = [];
    if (!searchUsers || isEmpty(searchUsers)) {
        return filteredSearchUsers;
    }
    filteredSearchUsers = searchUsers.filter(searchUser => {
        if (!(searchUser.name.includes(searchWord) || searchUser.username.includes(searchWord))) {
            return false;
        }

        if (roleId !== DROPDOWN_SELECT_TEXT_VALUE && searchUser.role.id !== Number(roleId)) {
            return false;
        }

        return true;
    });

    return filteredSearchUsers;
};

export const getQueryParamsAsObject = url => {
    const paramObj = {};
    if (!url.includes(QUERY_SPLITTER)) {
        return paramObj;
    }
    const queryParams = url.split(QUERY_SPLITTER)[1];
    const params = queryParams.split(QUERY_PARAMS_SPLITTER);

    params.forEach(param => {
        const pair = param.split(PARAM_SPLITTER);
        paramObj[pair[0]] = pair[1].trim();
    });

    return paramObj;
};

export const maxEmailLength = 254;
export const notValidEmail = email => {
    if (isEmpty(email)) {
        return 'REQUIRED_FIELD';
    }
    if (!email.match(EMAIL_REGEXP) || email.length > maxEmailLength) {
        return 'EMAIL_NOT_VALID';
    }

    return false;
};

export const getInfoByTextType = (infos, textType) => {
    let requiredInfo = {};
    const filteredInfo = infos.filter(info => info.textType === Number(textType));
    if (!isEmpty(filteredInfo)) {
        requiredInfo = filteredInfo[0];
    }

    return requiredInfo;
};

export const getDateTimeFormat = (date, time) => {
    if (time) {
        if (typeof time === 'string') {
            return `${moment(date).format('YYYY-MM-DD')} ${time}`;
        }

        return `${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`;
    }

    return `${moment(date).format('YYYY-MM-DD')} ${moment(date).format('HH:mm')}`;
};

export const getDateFormat = date => moment(date).format('YYYY-MM-DD');

export const getTimeFormat = time => {
    if (typeof time === 'string') {
        return `${time}`;
    }

    return moment(time).format('HH:mm');
};
